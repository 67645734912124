import React, { useState } from "react";
import styled from "@emotion/styled";
import theme from "../../styles/theme";
import { Smaller } from "../text";
import PropTypes from "prop-types";

const Label = styled.label`
    position: relative;
    border: 0;
    display: block;
    cursor: pointer;

    &:after {
        content: "";
        display: table;
        clear: both;
    }
`;

const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const Box = styled.span`
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    border: solid 2px currentColor;
    float: left;

    ${Input} ~ & {
        &:after {
            content: "";
            position: absolute;
            left: 4px;
            top: 1px;
            display: block;
            width: 5px;
            height: 10px;
            border: solid 3px currentColor;
            border-width: 0 3px 3px 0;
            box-sizing: content-box;
            transform: rotate(45deg) scale3d(0, 0, 1);
            transition: transform 0.1s;
            transform-origin: 50% 75%;
        }
    }
    ${Input}:checked ~ & {
        &:after {
            transform: rotate(45deg) scale3d(1, 1, 1);
        }
    }
    ${Label}:focus-within & {
        border-color: ${theme.colors.spot1};
    }
`;

const Text = styled(Smaller)`
    display: block;
    margin-left: 38px;
`;

function ChoiceInput({ label, placeholder, type, className, ...others }) {
    const [value, setValue] = useState("");

    const handleChange = e => {
        setValue(e.target.value);
    };
    const trimValue = e => {
        setValue(e.target.value.trim());
    };
    return (
        <Label className={className}>
            <Input
                {...others}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={trimValue}
            />
            <Box />
            <Text>{label}</Text>
        </Label>
    );
}

ChoiceInput.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["checkbox", "radio"]),
    placeholder: PropTypes.string
};
ChoiceInput.defaultProps = {
    type: "checkbox",
    placeholder: " "
};

export default ChoiceInput;
