/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import theme from "../styles/theme";

const Anchor = styled.a`
    margin-top: calc(-1 * ${theme.fluidGrid.mdToX5l.margin});
    padding-top: ${theme.fluidGrid.mdToX5l.margin};
`;

export default props => <Anchor {...props} />;
