import React, { useState } from "react";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import theme from "../styles/theme";

function Collapse({ title, children, ...others }) {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    const ariaTitle = title.replace(/[\s&]/g, "-").toLowerCase();
    return (
        <>
            <h3
                css={css`
                    font-size: inherit;
                    margin: 0;
                    padding: 0;
                `}
            >
                <button
                    aria-expanded={expanded}
                    aria-controls={`content-${ariaTitle}`}
                    id={`title-${ariaTitle}`}
                    onClick={toggleExpanded}
                    css={css`
                        background: transparent;
                        display: block;
                        width: 100%;
                        color: inherit;
                        border: none;
                        margin: 0;
                        padding: 0;
                        outline: 0;
                        text-align: left;
                        cursor: pointer;
                    `}
                >
                    <span
                        css={css`
                            color: ${theme.colors.spot1};
                            font-weight: 700;
                            display: inline-block;
                            position: relative;
                            left: 0;
                            top: -0.1em;

                            min-width: 1em;
                        `}
                    >
                        {expanded ? "–" : "+"}
                    </span>{" "}
                    {title}
                </button>
            </h3>
            <div
                role="region"
                aria-labelledby={`title-${ariaTitle}`}
                id={`content-${ariaTitle}`}
                css={css`
                    overflow: hidden;
                    ${expanded ? `height: auto;` : `height: 0px;`}
                    padding: 0 0 0.75em;
                `}
            >
                <div
                    css={css`
                        padding: 0.75em 0 0;
                    `}
                >
                    {children}
                </div>
            </div>
        </>
    );
}

function Accordion({ children, ...otherProps }) {
    return <div {...otherProps}>{children}</div>;
}

Accordion.Item = Collapse;
export default Accordion;
