import React, { useState } from "react";
import styled from "@emotion/styled";
import theme from "../../styles/theme";
import PropTypes from "prop-types";

const Label = styled.label`
    position: relative;
    border: 0;
    display: block;

    span {
        position: absolute;
        left: 0;
        top: 1.2em;
        transition: transform 0.2s, color 0.2s;
        transform-origin: 0 0;
        pointer-events: none;
    }
    &:focus-within {
        span {
            color: ${theme.colors.spot1};
        }
    }

    input {
        display: block;
        width: 100%;
        background: transparent;
        border: 0;
        border-bottom: 2px solid ${theme.colors.main};
        padding: 1em 0;
        outline: 0;
        transition: border-color 0.25s;

        &:focus,
        &:not(:placeholder-shown) {
            + span {
                transform: translate3d(0, -23px, 0) scale(0.5);
            }
        }

        &:focus {
            border-color: ${theme.colors.spot1};
        }
    }
`;

function TextInput({ label, placeholder, className, ...others }) {
    const [value, setValue] = useState("");

    const handleChange = e => {
        setValue(e.target.value);
    };
    const trimValue = e => {
        setValue(e.target.value.trim());
    };
    return (
        <Label className={className}>
            <input
                {...others}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={trimValue}
            />
            <span>{label}</span>
        </Label>
    );
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string
};
TextInput.defaultProps = {
    placeholder: " "
};

export default TextInput;
