import { useRef } from "react";
import PropTypes from "prop-types";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Motion, spring } from "react-motion";

/**
 * https://varun.ca/metaballs/
 * Based on Metaball script by SATO Hiroyuki
 * http://shspage.com/aijs/en/#metaball
 */
function metaball(
    radius1,
    radius2,
    center1,
    center2,
    handleSize = 2.4,
    v = 0.5
) {
    const HALF_PI = Math.PI / 2;
    const d = dist(center1, center2);
    const maxDist = radius1 + radius2 * 2.5;
    let u1, u2;

    if (
        radius1 === 0 ||
        radius2 === 0 ||
        d > maxDist ||
        d <= Math.abs(radius1 - radius2)
    ) {
        return "";
    }

    if (d < radius1 + radius2) {
        u1 = Math.acos(
            (radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d)
        );
        u2 = Math.acos(
            (radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d)
        );
    } else {
        u1 = 0;
        u2 = 0;
    }

    // All the angles
    const angleBetweenCenters = angle(center2, center1);
    const maxSpread = Math.acos((radius1 - radius2) / d);

    const angle1 = angleBetweenCenters + u1 + (maxSpread - u1) * v;
    const angle2 = angleBetweenCenters - u1 - (maxSpread - u1) * v;
    const angle3 =
        angleBetweenCenters + Math.PI - u2 - (Math.PI - u2 - maxSpread) * v;
    const angle4 =
        angleBetweenCenters - Math.PI + u2 + (Math.PI - u2 - maxSpread) * v;
    // Points
    const p1 = getVector(center1, angle1, radius1);
    const p2 = getVector(center1, angle2, radius1);
    const p3 = getVector(center2, angle3, radius2);
    const p4 = getVector(center2, angle4, radius2);

    // Define handle length by the
    // distance between both ends of the curve
    const totalRadius = radius1 + radius2;
    const d2Base = Math.min(v * handleSize, dist(p1, p3) / totalRadius);

    // Take into account when circles are overlapping
    const d2 = d2Base * Math.min(1, (d * 2) / (radius1 + radius2));

    const r1 = radius1 * d2;
    const r2 = radius2 * d2;

    const h1 = getVector(p1, angle1 - HALF_PI, r1);
    const h2 = getVector(p2, angle2 + HALF_PI, r1);
    const h3 = getVector(p3, angle3 + HALF_PI, r2);
    const h4 = getVector(p4, angle4 - HALF_PI, r2);

    return metaballToPath(p1, p2, p3, p4, h1, h2, h3, h4, d > radius1, radius2);
}

function metaballToPath(p1, p2, p3, p4, h1, h2, h3, h4, escaped, r) {
    return [
        "M",
        p1,
        "C",
        h1,
        h3,
        p3,
        "A",
        r,
        r,
        0,
        escaped ? 1 : 0,
        0,
        p4,
        "C",
        h4,
        h2,
        p2
    ].join(" ");
}

function dist([x1, y1], [x2, y2]) {
    return ((x1 - x2) ** 2 + (y1 - y2) ** 2) ** 0.5;
}

function angle([x1, y1], [x2, y2]) {
    return Math.atan2(y1 - y2, x1 - x2);
}

function getVector([cx, cy], a, r) {
    return [cx + r * Math.cos(a), cy + r * Math.sin(a)];
}

function BubbleNav({
    items,
    distance,
    radius,
    activeIndex,
    activeRadius,
    className
}) {
    const container = useRef(null);
    const padding = distance - 2 * radius;
    const svgHalfWidth = padding + radius;
    const svgWidth = 2 * svgHalfWidth;
    const svgHeight = distance * (items.length - 1) + 2 * (padding + radius);
    // const [mousePosition, setMousePosition] = useState([]);
    // const pointerPosition = [
    //     svgWidth,
    //     typeof mousePosition[1] != "undefined" && container.current
    //         ? mousePosition[1] - container.current.getBoundingClientRect().top
    //         : padding + radius + activeIndex * distance
    // ];
    const springConfig = {
        stiffness: 130,
        damping: 9
    };
    const pointerPosition = [
        svgWidth,
        padding + radius + activeIndex * distance
    ];
    const bubbleCoords = items.map((item, index) => [
        svgHalfWidth,
        padding + radius + index * distance
    ]);
    const renderedBubbles = bubbleCoords.map((coords, index) => (
        <circle
            key={`bubble-${index}`}
            cx={coords[0]}
            cy={coords[1]}
            r={radius}
        />
    ));
    // const handleMouse = e => {
    //     setMousePosition([e.nativeEvent.clientX, e.nativeEvent.clientY]);
    // };
    // const killMouse = () => {
    //     setMousePosition([]);
    // };
    let neighbourBubblesY = [0, 0];

    for (let i = 0, len = bubbleCoords.length; i < len; i++) {
        if (pointerPosition[1] < bubbleCoords[i][1]) {
            neighbourBubblesY = [
                bubbleCoords[Math.max(0, i - 1)][1],
                bubbleCoords[i][1]
            ];
            break;
        } else if (len === i + 1) {
            neighbourBubblesY = [bubbleCoords[i][1], bubbleCoords[i][1]];
        }
    }

    const path0 = y =>
        metaball(
            radius,
            activeRadius,
            [svgHalfWidth, neighbourBubblesY[0]],
            [svgHalfWidth, y]
        );
    const path1 = y =>
        neighbourBubblesY[0] === neighbourBubblesY[1]
            ? ""
            : metaball(
                  radius,
                  activeRadius,
                  [svgHalfWidth, neighbourBubblesY[1]],
                  [svgHalfWidth, y]
              );
    return (
        <div
            className={className}
            css={css`
                position: relative;
                display: inline-block;
                margin: -${svgHeight / 2}px -${svgHalfWidth}px;
            `}
            // onMouseMove={handleMouse}
            // onMouseLeave={killMouse}
            ref={container}
            // role="menu"
            // tabIndex={0}
        >
            <svg
                viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                preserveAspectRatio="xMidYMid slice"
                css={css`
                    display: block;
                    width: ${svgWidth}px;
                `}
            >
                <g fill="#2cff48">
                    {renderedBubbles}
                    <Motion
                        defaultStyle={{
                            y: padding + radius + activeIndex * distance
                        }}
                        style={{ y: spring(pointerPosition[1], springConfig) }}
                    >
                        {({ y }) => (
                            <g>
                                <circle
                                    cx={svgWidth / 2}
                                    cy={y}
                                    r={activeRadius}
                                />
                                <path d={path0(y)} />
                                <path d={path1(y)} />
                            </g>
                        )}
                    </Motion>
                    {/* <circle
                        cx={svgWidth / 2}
                        cy={pointerPosition[1]}
                        r={activeRadius}
                    />
                    <path d={path0} />
                    <path d={path1} /> */}
                </g>
            </svg>
            {/* <ul
                css={css`
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    list-style: none;

                    > li {
                        margin: 0;
                        padding: 0;
                        overflow: hidden;
                        flex-grow: 1;
                    }
                `}
            >
                {renderedLinks}
            </ul> */}
        </div>
    );
}
BubbleNav.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    distance: PropTypes.number,
    radius: PropTypes.number,
    activeIndex: PropTypes.number,
    activeRadius: PropTypes.number
};
BubbleNav.defaultProps = {
    distance: 44,
    radius: 8,
    activeIndex: 0,
    activeRadius: 10
};
export default BubbleNav;
