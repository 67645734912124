import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import isPropValid from "@emotion/is-prop-valid";
import theme from "../styles/theme";
import { Smaller } from "../components/text";
import { useStaticQuery, graphql } from "gatsby";

const CaseDescription = styled.span(
    css`
        position: absolute;
        padding: ${theme.fluidGrid.mdToX5l.gutter};
        margin: -0.4em 0;
        z-index: 10;
        transition: opacity 0.35s;
        pointer-events: none;
    `,
    props => `
        ${props.position.map(p => `${p}: 0;`).join(" ")}
        opacity: ${props.playing ? 0.0001 : 1};
        `
);

const CaseVideo = styled("video", {
    shouldForwardProp: prop =>
        isPropValid(prop) || prop === "muted" || prop === "playsinline"
})(
    css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity 0.2s;
        background-color: ${theme.colors.background};
    `,
    props => `opacity: ${props.playing ? 1 : 0.0001};`
);

const Image = styled(Img)`
    pointer-events: none;
`;

function VideoTile({
    color,
    bgColor,
    videoSrc,
    imageSrc,
    imageAlt,
    description: { title, text, position = ["left", "top"] }
}) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        videoBaseUrl
                    }
                }
            }
        `
    );
    const video = useRef(),
        videoPromise = useRef();
    const [playing, setPlaying] = useState(false);

    var getVideo = () => ({
        _: video.current,
        isPlaying: video.current
            ? video.current.currentTime > 0 &&
              !video.current.paused &&
              !video.current.ended &&
              video.current.readyState > 2
            : undefined,
        promise: videoPromise.current
    });

    const play = () => {
        const playCmds = () => {
            const vid = getVideo();
            if (!vid.isPlaying) {
                vid._.currentTime = 0;
                videoPromise.current = vid._.play();
            }
        };
        if (videoPromise.current !== undefined) {
            videoPromise.current.then(_ => playCmds());
        } else {
            playCmds();
        }
    };
    const pause = () => {
        const pauseCmds = () => {
            const vid = getVideo();
            if (vid._) {
                vid._.pause();
                vid._.currentTime = 0;
            }
        };
        if (videoPromise.current !== undefined) {
            videoPromise.current.then(_ => pauseCmds());
        } else {
            pauseCmds();
        }
    };
    useEffect(() => {
        if (playing) {
            play();
        } else {
            pause();
        }
        return () => {
            pause();
        };
    }, [playing]);
    useEffect(() => {
        video.current.setAttribute("muted", "muted");
    }, []);

    const startVideo = e => {
        e.stopPropagation();
        setPlaying(true);
    };
    const stopVideo = e => {
        e.stopPropagation();
        setPlaying(false);
    };

    return (
        <div
            style={{
                color: color,
                backgroundColor: bgColor
            }}
            role="figure"
        >
            <Image fluid={imageSrc} alt={imageAlt} />
            <CaseVideo
                src={`${site.siteMetadata.videoBaseUrl}${videoSrc}`}
                ref={video}
                playing={playing}
                muted
                playsInline
                onEnded={stopVideo}
                onMouseOver={startVideo}
                onMouseOut={stopVideo}
            />
            <CaseDescription position={position} playing={playing}>
                {title}
                <br />
                <Smaller
                    css={css`
                        display: block;
                        margin-top: -0.2em;
                    `}
                >
                    {text}
                </Smaller>
            </CaseDescription>
        </div>
    );
}

VideoTile.propTypes = {
    color: PropTypes.string,
    bgColor: PropTypes.string,
    videoSrc: PropTypes.string.isRequired,
    imageSrc: PropTypes.object.isRequired,
    imageAlt: PropTypes.string,
    description: PropTypes.object.isRequired
};
VideoTile.defaultProps = {
    color: "inherit",
    bgColor: "transparent",
    imageAlt: ""
};

export default VideoTile;
