import theme, { mediaquery } from "../../styles/theme";
import adaptiveCssValue from "../../helpers/adaptiveCssValue";
import PropTypes from "prop-types";
/** @jsx jsx */
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const roundNumber = value => Math.floor(value * 100 * 100) / 100;

const Tiles = styled.div(
    css`
        display: flex;
        flex-wrap: wrap;
        margin: ${adaptiveCssValue(
            theme.breakpointByAlias["md"],
            theme.breakpointByAlias["x5l"],
            theme.grid.sm.gutter / -2,
            theme.grid.x5l.gutter / -2
        )};

        > * {
            display: block;
            position: relative;
            margin: ${adaptiveCssValue(
                theme.breakpointByAlias["md"],
                theme.breakpointByAlias["x5l"],
                theme.grid.sm.gutter / 2,
                theme.grid.x5l.gutter / 2
            )};
            height: 0;
        }
    `,
    props => {
        // col rules
        // TODO combine Tiles breakpoints with grid breakpoints
        const breakpointsInUse = Object.keys(props.cols);
        const firstBreakpointAlias = theme.breakpointAlias[0];
        const generateRule = (v, k) => {
            let value = `${roundNumber(1 / v)}%`;
            if (value !== 100) {
                value = `calc(${value} - ${theme.fluidGrid.mdToX5l.gutter})`;
            }
            return `
                width: ${value};
                padding-bottom: ${value};
            `;
        };
        let rules = "";
        if (!breakpointsInUse.includes(firstBreakpointAlias)) {
            // default rule for smallest size
            rules += generateRule(1);
        }
        breakpointsInUse.forEach(k => {
            const v = props.cols[k];
            if (k === firstBreakpointAlias) {
                rules += generateRule(v, k);
            } else {
                rules += mediaquery(k, generateRule(v, k));
            }
        });
        return css`
            > * {
                ${rules}
            }
        `;
    }
);

Tiles.propTypes = {
    children: PropTypes.node,
    cols: PropTypes.objectOf(PropTypes.number)
};
Tiles.defaultProps = {
    children: null,
    cols: {
        sm: 1
    }
};

export default Tiles;
