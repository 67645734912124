import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import theme from "../../styles/theme";
import PropTypes from "prop-types";

const Label = styled.label`
    position: relative;
    border: 0;
    padding: 1em 0 0;
    display: block;

    span {
        position: absolute;
        left: 0;
        top: 1.2em;
        transition: transform 0.2s, color 0.2s;
        transform-origin: 0 0;
        pointer-events: none;
    }
    &:focus-within {
        span {
            color: ${theme.colors.spot1};
        }
    }

    textarea,
    div {
        display: block;
        width: 100%;
        background: transparent;
        border: 0;
        border-bottom: 2px solid ${theme.colors.main};
        padding: 0 0 1em;
        outline: 0;
        transition: border-color 0.25s;
        resize: none;

        &:focus,
        &:not(:placeholder-shown) {
            + span {
                transform: translate3d(0, -23px, 0) scale(0.5);
            }
        }

        &:focus {
            border-color: ${theme.colors.spot1};
        }
    }
    div {
        position: absolute !important;
        right: calc(100% + 10px);
        top: 0;
        // height: 1px;
        width: 100%;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap; /* added line */
        opacity: 0;
        pointer-events: none;
    }
`;

function TextInput({ label, placeholder, maxHeight, className, ...others }) {
    const dummy = useRef();
    const [value, setValue] = useState("");
    const [height, setHeight] = useState("0px");
    const handleChange = e => {
        setValue(e.target.value);
    };
    const trimValue = e => {
        setValue(e.target.value.trim());
    };
    useEffect(() => {
        setHeight(value ? `${dummy.current.offsetHeight}px` : `auto`);
        return;
    }, [value]);
    return (
        <Label className={className}>
            <div
                ref={dummy}
                dangerouslySetInnerHTML={{
                    __html:
                        value
                            .replace(/ /g, "\u00a0")
                            .replace(/(?:\r\n|\r|\n)$/g, "<br />\u00a0")
                            .replace(/(?:\r\n|\r|\n)/g, "<br />") || "\u00a0"
                }}
            ></div>
            <textarea
                {...others}
                value={value}
                rows="1"
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={trimValue}
                style={{ maxHeight, height }}
            />
            <span>{label}</span>
        </Label>
    );
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    maxHeight: PropTypes.number
};
TextInput.defaultProps = {
    placeholder: " ",
    maxHeight: 200
};

export default TextInput;
